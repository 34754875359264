import React from "react";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";

const HomepageBanner = ({ bannerContent }) => (
  <section
    className="homepage-banner"
    style={{
      backgroundImage: `url(${bannerContent.background.url})`,
    }}
  >
    <div className="banner-container container">
      <div className="banner-content">
        <h2 className="banner-title">
          {RichText.asText(bannerContent.title.raw)}
        </h2>
        <p className="banner-description">
          {RichText.asText(bannerContent.description.raw)}
        </p>
        <Link to={bannerContent.link.url} className="banner-button">
          {RichText.asText(bannerContent.linkLabel.raw)}
        </Link>
      </div>
    </div>
  </section>
);

export default HomepageBanner;
